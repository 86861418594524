// utils/formatCurrency.ts
export const formatCurrency = (value: number | string) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value));
};

// Função para obter o nome do mês
export const getMonthName = (isoDateString: string) => {
  const [year, month] = isoDateString.split('-'); // Separa o ano e o mês
  const date = new Date(`${year}-${month}-01`); // Cria uma data com o primeiro dia do mês
  return date.toLocaleString('pt-BR', { month: 'long', year: 'numeric' }); // Retorna o nome do mês e ano formatados
};
