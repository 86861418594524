import React, { ChangeEvent } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

interface CurrencyInputProps extends Omit<FormControlProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Permite a entrada de números e vírgula
    inputValue = inputValue.replace(/[^\d,]/g, '');

    onChange(inputValue);
  };

  const handleBlur = () => {
    // Formata o valor ao perder o foco
    const parts = value.split(',');
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Adiciona separadores de milhar
    let decimalPart = parts[1] ? parts[1].slice(0, 2) : '00'; // Limita a duas casas decimais

    // Se não houver parte decimal, adiciona "00"
    if (decimalPart.length === 1) {
      decimalPart += '0';
    }

    const formattedValue = `R$ ${integerPart},${decimalPart}`;
    onChange(formattedValue);
  };

  return (
    <Form.Control
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

// Função para remover a formatação e converter para número
export const convertToNumber = (formattedValue: string): number => {
  return Number(formattedValue.replace(/[^\d,]/g, '').replace(',', '.'));
};

export default CurrencyInput;
